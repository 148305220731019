import Logo from "@/components/logo";
import NavigationMenu from "./navigation-menu";
import UniquePlanButton from "./navigation/unique-plan-button";

interface HeaderButtonsProps {
    children: React.ReactNode;
    menuOpen: boolean;
    setMenuOpen: (menuOpen: boolean) => void;
    duration: number;
    storyBookOnly?: boolean;
    authButton?: React.ReactElement;
}

const HeaderButtons: React.FC<HeaderButtonsProps> = ({
    children,
    menuOpen,
    setMenuOpen,
    duration,
    authButton,
    storyBookOnly = false,
}) => {
    return (
        <>
            <div className="flex flex-row w-full justify-between items-center p-2 z-10 h-[--header-height] relative top-0 pl-10 pr-10">
                <div className="w-40">
                    <Logo />
                </div>
                {/* <PlanHeaderButton
                    menuOpen={menuOpen}
                    onClickHandler={() => setMenuOpen(true)}
                    duration={duration}
                /> */}
                <UniquePlanButton onClickHandler={() => setMenuOpen(true)} />
                <div className="w-40 flex flex-row justify-end">
                    <NavigationMenu
                        storyBookProps={null}
                        authButton={authButton}
                    />
                </div>
            </div>
            {children}
            {/* <ActivitiesMenu /> */}
        </>
    );
};

export { HeaderButtons };

const ManchesterMap = () => {
    return (
        <svg
            className="w-full h-full"
            viewBox="0 0 82 57"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M 55 1 L 54 7 L 48 2 C 45.309 4.62 44.898 7.41 44 11 H 42 L 41 5 L 35 4 V 8 C 32.147 8.28 30.397 8.56 28 7 C 22.547 10.54 15.206 10.3 10 14 C 3.533 9.92 -0.423 20.39 1.642 25.62 C 3.662 30.75 10.338 31.45 12 37 L 22 32 C 23.543 36.98 25.504 40.68 25 46 C 29.804 47.58 34.092 50.53 39 51.91 C 42.076 52.78 44.928 50.93 47.871 51.73 C 50.167 52.35 51.95 54.83 54 56 C 54.99 49.95 58.415 51.93 62.982 50.03 C 66.212 48.68 67.745 46.06 69 43 C 63.649 36.65 75.325 29.64 76.128 23.95 C 76.875 18.66 68.49 12.5 66.025 7.91 C 65.001 6.01 64.602 3.32 62.636 2.1 C 60.631 0.86 57.285 1.25 55 1 Z"
                fill="lightgrey"
                stroke="grey"
                strokeWidth="1"
            ></path>
        </svg>
    );
};

export default ManchesterMap;

import { SetStateAction, useEffect, useState } from "react";

const useOpenClosedAnimation: (
    menuOpen: boolean
) => [string, boolean, (value: SetStateAction<boolean>) => void] = (
    menuOpen
) => {
    const [isHidden, setIsHidden] = useState(true);
    const [activeVariant, setActiveVariant] = useState(
        menuOpen ? "menuOpen" : "menuClosed"
    );

    useEffect(() => {
        if (isHidden && !menuOpen) {
            setIsHidden(false);
        }
        setActiveVariant(menuOpen ? "menuOpen" : "menuClosed");
    }, [menuOpen]);

    return [activeVariant, isHidden, setIsHidden];
};

export { useOpenClosedAnimation };

import { ReactElement, createElement, useEffect, useState } from "react";

import { motion } from "framer-motion";

type LoaderProps = {
    loaderName: string;
    speed?: number;
    color?: string;
    size?: number;
    stroke?: number;
};

const Loader: (props: LoaderProps) => ReactElement<any, any> = ({
    loaderName,
    ...props
}) => {
    const elementName = `ldrs-${loaderName}`.toLowerCase();

    useEffect(() => {
        async function getLoader() {
            const allLoaders = await import("ldrs");
            const namedLoader = allLoaders[loaderName];

            namedLoader.register(elementName);
        }
        getLoader();
    }, []);

    return createElement(elementName, props);
};

export { Loader };

const bubbleVariants = (delay, animationHeight, xOffset) => {
    const animationProps = {
        duration: 0.7,
        // Assuming you want to keep one ease function for simplicity
        ease: "easeInOut",
        repeatDelay: delay,
        delay,
    };

    return {
        animate: {
            opacity: 1,
            y: [20, -animationHeight], // Adjust these values based on your container size and desired effect
            x: [0, xOffset],
            transition: {
                x: { ...animationProps }, // Customize as needed
                y: { ...animationProps }, // Keep shared properties
            },
        },
        initial: {
            opacity: 0,
        },
    };
};

const Bubble = ({ maxSize, left, delay, animationHeight }) => {
    const [size, setSize] = useState(3 + Math.random() * maxSize);
    const [randomDelay, setDelay] = useState(Math.random() * delay);
    const [xOffset, setXOffset] = useState(-5 + Math.random() * 10);
    const [animate, setAnimate] = useState(true);

    const [key, setKey] = useState(0); // Initialize a key state

    const updateSize = () => {
        const randomValue = Math.random();
        const inverseRandomValue = 1 - randomValue;
        setSize(inverseRandomValue * maxSize);
        setDelay(randomValue * delay);
        setKey((prev) => prev + 1); // Increment the key to force a re-render
    };

    return (
        <motion.div
            onAnimationComplete={updateSize}
            key={key} // Use the key to force a re-render
            style={
                {
                    opacity: 0,
                    position: "absolute",
                    bottom: "0px",
                    width: `${size}px`,
                    height: `${size}px`,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    borderRadius: "50%",
                    left: `${left}%`,
                } as unknown as React.CSSProperties
            }
            variants={bubbleVariants(randomDelay, animationHeight, xOffset)}
            animate="animate"
            custom={randomDelay}
        />
    );
};

const BubblesAnimation = () => {
    const height = 30;
    const [maxSize, _setMaxBubbleSize] = useState(10);
    const leftPositions = [5, 10, 20, 25, 30, 35, 45, 50];

    return (
        <div
            // className="overflow-y-hidden"
            style={{
                position: "relative",
                width: "60px",
                height: `${height}px`,
                // overflow: "hidden",
            }}
        >
            {leftPositions.map((left, index) => (
                <Bubble
                    key={`${left}`}
                    maxSize={maxSize}
                    left={left + 10}
                    delay={index * 0.5 * Math.random()}
                    animationHeight={height}
                />
            ))}
        </div>
    );
};

export { BubblesAnimation };

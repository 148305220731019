"use client";

import { AnimatePresence, Variants, motion } from "framer-motion";
import { BubblesAnimation } from "@/components/ui/loader";
import { ReactElement, useEffect, useState } from "react";
import { cn, verboseLog } from "@/lib/utils";

import { CommonPanelProps } from "@/type/app/layout/takeover-form/common-panel-props";
import { DateTime } from "@/type/plan/unique-flow";
import { InputWithLabel } from "@/components/ui/input-with-label";
import UniquePlanStatus from "./unique-plan-status";
import { useDebounceStateChange } from "@/hooks/debounce-hooks";
import { usePlanFormSave } from "@/hooks/unique-plan";

const menuItems = [
    ["Create Event", "/plan"],
    ["FAQ", "/faq"],
];

const variants: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    // transition: { duration: 300 },
};

enum CompletionState {
    Good = "good",
    Bad = "bad",
    Great = "great",
    NotStarted = "not started",
}

const animationProps = {
    variants,
    initial: "initial",
    animate: "animate",
    exit: "exit",
    transition: { duration: 0.15, ease: "easeInOut" },
};

const completionStatuses = new Map<CompletionState, ReactElement>([
    [
        CompletionState.NotStarted,
        <motion.div {...animationProps} key={CompletionState.NotStarted}>
            <div className="max-w-lg">
                What’s the occasion? What’s the vibe? How many attending? What
                are you looking to do? And when?
            </div>
        </motion.div>,
    ],
    [
        CompletionState.Bad,
        <motion.div {...animationProps} key={CompletionState.Bad}>
            <div className="text-gatha-secondary-white">
                We'll need a bit more information to help you out.
            </div>
        </motion.div>,
    ],
    [
        CompletionState.Good,
        <motion.div {...animationProps} key={CompletionState.Good}>
            <div className="text-gatha-primary-green">We're almost there!</div>
        </motion.div>,
    ],
    [
        CompletionState.Great,
        <motion.div {...animationProps} key={CompletionState.Great}>
            <div>Great job! We'll take it from here.</div>
        </motion.div>,
    ],
]);

const getCompletionState = (inputValue) => {
    const words = inputValue.split(" ");
    const wordCount = words.length;

    if (wordCount < 2) {
        return CompletionState.NotStarted;
    }

    if (wordCount < 20) {
        return CompletionState.Bad;
    }

    if (wordCount < 28) {
        return CompletionState.Good;
    }

    return CompletionState.Great;
};

const updateCompletionStatus = (setCompletionState, inputValue) => {
    setCompletionState(getCompletionState(inputValue));
};

type ProgressDescriptionProps = {
    completionState: CompletionState;
};

const ProgressDescription = ({ completionState }: ProgressDescriptionProps) => {
    return (
        <AnimatePresence mode="wait">
            {completionStatuses.get(completionState)}
        </AnimatePresence>
    );
};

const COMPLETION_STATE_DEBOUNCE_MS = 400;

const UniquePlanVision: React.FC<CommonPanelProps> = ({
    storyBookProps,
    existingPlan,
    navigateToPanel,
}) => {
    const [visionText, setVisionText] = useState(
        existingPlan ? existingPlan.summary : ""
    );
    const [completionState, setCompletionState] = useState(
        getCompletionState(visionText)
    );

    const {
        canSave,
        saveProvidedPlan,
        updatedPlan,
        failureReason,
        globalPlan,
    } = storyBookProps
        ? {
              canSave: true,
              saveProvidedPlan: () => {},
              updatedPlan: null,
              failureReason: null,
              globalPlan: {},
              ...storyBookProps,
          }
        : usePlanFormSave();

    useEffect(() => {
        verboseLog("Plan updated", updatedPlan);

        if (updatedPlan?.planId) {
            navigateToPanel(DateTime);
        }
    }, [updatedPlan]);

    useEffect(() => {
        verboseLog("Plan update failed", failureReason);
    }, [failureReason]);

    useDebounceStateChange(
        [visionText],
        () => {},
        () => {
            updateCompletionStatus(setCompletionState, visionText);
        },
        COMPLETION_STATE_DEBOUNCE_MS
    );

    const textComplete = completionState === CompletionState.Great;

    return (
        <div className="relative w-full h-full">
            {!canSave ? (
                <div className="flex flex-col justify-center items-center w-full h-full absolute top-0 bg-white z-10 opacity-90 gap-4">
                    Creating your plan, this will take a few seconds...
                    <BubblesAnimation />
                </div>
            ) : null}
            <div className="w-full h-full relative z-0">
                <div className="flex flex-col gap-14 justify-between h-full">
                    <UniquePlanStatus
                        status={{ completed: [false, false, false], stage: 1 }}
                    />
                    <div className="flex flex-col justify-between gap-4 w-full">
                        <h2 className="text-lg font-medium ">
                            Tell us what is in your mind, we’ll do the rest
                        </h2>
                        <div className="flex flex-col justify-end text-sm text-gatha-text h-14">
                            <ProgressDescription
                                completionState={completionState}
                            />
                        </div>
                        <div className="h-36 w-full">
                            <InputWithLabel
                                placeholder={`Example:

It's my 30th birthday, Looking to do something fun - maybe an activity with a cool vibe (there are 8 of us including me - boys and girls). Nothing too wild but needs to feel a bit more special than something we'd do any other weekend! Happy anywhere in London but ideally east side. Thinking this weekend or next.`}
                                formId="ai-description"
                                type="textarea"
                                className="w-full text-xs"
                                onChange={(event) => {
                                    verboseLog(event.target.value);
                                    setVisionText(event.target.value);
                                }}
                                value={visionText}
                            />
                        </div>
                        <div className="flex flex-row justify-end">
                            <button
                                disabled={!canSave}
                                onClick={async () => {
                                    if (textComplete) {
                                        await saveProvidedPlan({
                                            ...globalPlan,
                                            summary: visionText,
                                        });
                                    }
                                }}
                                className={cn(
                                    "bg-gatha-dark-purple rounded-full flex flex-row p-3 pl-10 pr-10 items-center gap-2 justify-center w-64",
                                    textComplete
                                        ? "opacity-100 cursor-pointer"
                                        : "opacity-20 pointer-events-none"
                                )}
                            >
                                <div className="text-white font-light">
                                    {textComplete
                                        ? globalPlan?.planId
                                            ? "Update Plan"
                                            : "Create Plan"
                                        : "Detail Needed"}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UniquePlanVision;

import { twMerge } from "tailwind-merge";

type Props = {
    className?: string;
};

const ButtonArrow = ({ className }: Props) => {
    return (
        <svg
            className={twMerge(`stroke-white fill-transparent ${className}`)}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M1.167 5h11.666M9 1l4 4-4 4"
            ></path>
        </svg>
    );
};

export default ButtonArrow;

"use client";

import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";

import { HeaderBackground } from "@/components/ui/fills/header-background";
import { HeaderButtons } from "./header-buttons";
import TakeoverForm from "./takeover-form";
import { Variants } from "framer-motion";
import styles from "./small-header.module.css";
import { verboseLog } from "@/lib/utils";

const getShadow = () => {
    return (
        <div className="h-2 from-black opacity-10 bg-gradient-to-b relative -mb-2 ease-out"></div>
    );
};

const getBackgroundVariants: () => Variants = () => {
    verboseLog(`getComputedStyle(document.body).getPropertyValue(
        "--header-height"
    ) ${getComputedStyle(document.body).getPropertyValue("--header-height")}`);

    return {
        menuOpen: {
            height: getComputedStyle(document.body).getPropertyValue(
                "--header-height"
            ),
        },
        menuClosed: {
            height: getComputedStyle(document.body).getPropertyValue(
                "--header-height"
            ),
        },
    };
};

const duration = 0.25;

const SmallHeader = ({ showMenu, authButton }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeVariant, setActiveVariant] = useState("menuClosed");
    const [variants, setVariants] = useState({});
    const router = useRouter();

    useEffect(() => {
        setActiveVariant(menuOpen ? "menuOpen" : "menuClosed");
    }, [menuOpen]);

    useEffect(() => {
        setVariants(getBackgroundVariants());

        const handleEsc = (event) => {
            if (event.key === "Escape") {
                setMenuOpen(false);
            }
        };

        window.addEventListener("keydown", handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    useEffect(() => {
        setMenuOpen(false);
    }, [usePathname()]);

    return (
        <div className="w-full h-[--header-height] sticky top-0 z-20">
            <div className="flex flex-col relative  h-[--header-height] bg-gatha-primary-purple ">
                <div
                    className="flex flex-col bg-gray-100 bg-opacity-40 w-full items-center h-[--header-height] relative"
                    data-animate-class={styles.fadeInBackground}
                    data-animate-when-hidden="true"
                    data-trigger-id="scroll-shadow"
                >
                    <HeaderButtons
                        menuOpen={menuOpen}
                        authButton={authButton}
                        setMenuOpen={(value) => {
                            if (showMenu) {
                                setMenuOpen(value);
                            } else {
                                router.push("/new");
                            }
                        }}
                        duration={duration}
                    >
                        <div className="w-full h-[--header-height] overflow-hidden absolute top-0">
                            <HeaderBackground className="absolute top-0 w-full bg-gatha-primary-purple h-[--header-height] overflow-hidden" />
                        </div>
                    </HeaderButtons>
                </div>
                <TakeoverForm
                    duration={duration}
                    menuOpen={menuOpen}
                    showMenu={showMenu}
                    setMenuOpen={(value) => {
                        if (showMenu) {
                            setMenuOpen(value);
                        } else {
                            // router.push("/");
                        }
                    }}
                />
                {getShadow()}
            </div>
        </div>
    );
};

export default SmallHeader;

type Props = {
    status: {
        stage: number;
        completed: boolean[];
    };
};

const stages = [
    {
        name: "Vision",
    },
    {
        name: "Date & Time",
    },
    {
        name: "Location",
    },
];

const getCircleStyles = (futureStage: boolean, complete: boolean) => {
    debugger;
    if (futureStage && !complete) {
        return "opacity-10";
    }
};

const UniquePlanStatus = ({ status }: Props) => {
    const currentStatus = status ?? {
        stage: 1,
        completed: false,
    };

    return (
        <div className="flex flex-row justify-between items-center w-full text-xs gap-2 p-1">
            {stages.map((stage, index) => {
                return (
                    // <div
                    //     key={stage.name}
                    //     className={`flex flex-col items-center gap-1 ${
                    //         currentStatus.stage === index + 1
                    //             ? "text-black font-semibold"
                    //             : "text-gray-400"
                    //     }`}
                    // >
                    <div
                        className={`flex flex-row items-center gap-3 ${
                            index + 1 > currentStatus.stage ? "opacity-10" : ""
                        }`}
                        key={stage.name}
                    >
                        <div
                            className={`flex  items-center justify-center outline-2 outline rounded-full w-8 h-8 text-md ${
                                currentStatus.completed[index]
                                    ? "text-white bg-black"
                                    : ""
                            }`}
                        >
                            {index + 1}
                        </div>
                        <div>{stage.name}</div>
                        {index < stages.length - 1 ? (
                            <div className="border-t border-black w-7 h-0"></div>
                        ) : null}
                    </div>
                );
            })}
            {/* <div className="flex items-center justify-center outline-2 outline rounded-full w-8 h-8 text-md">
                1
            </div>
            <div>Vision</div>
            <div className="border-t border-black w-8 h-0"></div>
            <div className="flex items-center justify-center outline-2 outline rounded-full w-8 h-8 text-md">
                2
            </div>
            <div>Date & Time</div>
            <div className="border-t border-black w-8 h-0"></div>
            <div className="flex items-center justify-center outline-2 outline rounded-full w-8 h-8 text-md">
                3
            </div>
            <div>Location</div> */}
        </div>
    );
};

export default UniquePlanStatus;

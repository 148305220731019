const Vision = "vision";
const DateTime = "datetime";
const Location = "location";
const Intro = "intro";

type SearchHeaderSelection =
    | typeof Vision
    | typeof DateTime
    | typeof Location
    | typeof Intro;

export type { SearchHeaderSelection };
export { Vision, DateTime, Location, Intro };

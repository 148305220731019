const LondonMap = () => {
    return (
        <svg
            className="w-full h-full"
            viewBox="0 0 87 60"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <rect x="0" y="0" width="100%" height="100%" />

                <circle cx="50%" cy="50%" r="4" fill="white" /> */}
            <path
                d="M 3 10 C 3.45 15.68 4.46 21.27 3.9 27 C 3.47 31.35 1.15 34.6 2 39 C 11.52 36.76 20.1 43.15 20 53 C 21.97 51.13 23.49 49.26 25 47 C 28.11 50.16 33.88 58.05 38.1 59.25 C 42.52 60.5 46.33 50.43 50.4 56.99 C 50.9 57.8 50.87 59.17 51 60 C 52.2 59.4 51.98 59.53 53 58 C 54.54 59.27 55.05 59.49 57 60 C 58.09 56.4 60.99 54.38 62.49 50.99 C 63.87 47.85 63.41 44.33 64.56 41.17 C 65.62 38.26 67.85 35.93 69 33 C 64.85 29.92 62.33 27.55 57 29 C 60.84 25.97 64.67 26.71 67 31 H 71 C 71.82 25.79 74.91 23.68 80 23 C 78.41 19.66 76.57 17.17 73 16 L 74 12 C 67.55 9.41 62.34 13.34 56.05 12.5 C 52.96 12.09 50.99 8.94 48 8 V 2 C 44.42 1.72 40.53 0.52 37 1.56 C 25.35 5.01 15.96 15.49 3 10 Z"
                fill="lightgrey"
                stroke="grey"
                strokeWidth="1"
            ></path>
        </svg>
    );
};

export default LondonMap;

const CloseX = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="0.5"
                strokeWidth="1.5"
                d="M18 6L6 18M6 6l12 12"
            ></path>
        </svg>
    );
};

export default CloseX;

"use client";

import { cn, verboseLog } from "@/lib/utils";
import { useEffect, useState } from "react";

import { CommonPanelProps } from "@/type/app/layout/takeover-form/common-panel-props";
import { InputWithLabel } from "@/components/ui/input-with-label";
import LocationMap from "@/components/ui/buttons/location-map";
import UniquePlanStatus from "./unique-plan-status";
import { useDebounceStateChange } from "@/hooks/debounce-hooks";
import { usePlanFormSave } from "@/hooks/unique-plan";
import { useRouter } from "next/navigation";

const AUTO_SAVE_DEBOUNCE_MS = 500;

const UniquePlanLocation: React.FC<CommonPanelProps> = ({
    storyBookProps,
    existingPlan,
}) => {
    const [city, setCity] = useState(
        existingPlan?.eventLocation?.city ?? "London, UK"
    );
    const [area, setArea] = useState(
        existingPlan?.eventLocation.area ?? undefined
    );

    const router = useRouter();

    useEffect(() => {
        verboseLog("city updated: ", city);
    }, [city]);

    useEffect(() => {
        verboseLog("area updated: ", area);
    }, [area]);

    const { canSave, saveProvidedPlan, globalPlan } = storyBookProps
        ? {
              canSave: true,
              saveProvidedPlan: () => {},
              globalPlan: {},
              ...storyBookProps,
          }
        : usePlanFormSave();

    useDebounceStateChange(
        [area, city],
        () => {},
        () => {
            saveProvidedPlan({
                ...globalPlan,
                eventLocation: {
                    ...globalPlan.eventLocation,
                    address: `${area}, ${city}`,
                },
                location: area,
                city,
            });
        },
        AUTO_SAVE_DEBOUNCE_MS
    );

    return (
        <div className="flex flex-col relative gap-8 h-full justify-between">
            <UniquePlanStatus
                status={{ stage: 3, completed: [true, true, false] }}
            />
            <div className="flex flex-col justify-between gap-10 w-full">
                <div className="w-full flex flex-row gap-6  justify-between">
                    <div className="font-semibold text-sm">City</div>
                    <div className="w-fit flex flex-row gap-6">
                        <LocationMap
                            location="London, UK"
                            onClick={(location) => {
                                setCity(location);
                            }}
                        />
                        <LocationMap
                            location="Manchester, UK"
                            onClick={(location) => {
                                setCity(location);
                            }}
                            enabled={false}
                        />
                    </div>
                </div>

                <div className="w-full flex flex-row gap-10">
                    <div className="flex flex-col gap-1 w-52">
                        <div className="font-semibold text-sm">Area</div>
                        <div className="text-xs">
                            Want to change the area? Select from this list and
                            we'll do the rest.
                        </div>
                    </div>
                    <InputWithLabel
                        onChange={(event) => {
                            console.log(event.target.value);
                            setArea(event.target.value);
                        }}
                        className="max-w-xs"
                        formId="location"
                        type="dropdown"
                        placeholder={
                            existingPlan?.eventLocation?.address ?? "London, UK"
                        }
                        value={area}
                        options={[
                            { value: "E14", label: "East London" },
                            { value: "W1", label: "West London" },
                        ]}
                    />
                </div>
                <div className="flex flex-row justify-end">
                    <button
                        disabled={!globalPlan?.planId || !canSave}
                        className="bg-gatha-dark-purple rounded-full flex flex-row p-3 pl-20 pr-20 items-center gap-2 justify-center w-fit"
                        onClick={() => {
                            router.push(
                                `/plan/preview/${globalPlan.planId}`,
                                {}
                            );
                        }}
                    >
                        <div
                            className={cn(
                                "text-white font-light",
                                canSave ? "opacity-100" : "opacity-20"
                            )}
                        >
                            Create Invitation
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UniquePlanLocation;

"use client";

import {
    DateTime,
    Intro,
    Location,
    SearchHeaderSelection,
    Vision,
} from "@/type/plan/unique-flow";
import { useEffect, useState } from "react";

import ButtonArrow from "@/components/icons/button-arrow";
import CloseX from "@/components/icons/close-x";
import { CommonPanelProps } from "@/type/app/layout/takeover-form/common-panel-props";
import UniquePlanDateTime from "./navigation/unique-plan-date-time";
import UniquePlanIntro from "./navigation/unique-plan-intro";
import UniquePlanLocation from "./navigation/unique-plan-location";
import UniquePlanVision from "./navigation/unique-plan-vision";
import { motion } from "framer-motion";
import { notFound } from "next/navigation";
import { useAppContext } from "../AppContext";
import { useOpenClosedAnimation } from "@/hooks/animation-hooks";

const variants = {
    menuClosed: {
        scaleX: 0.5,
        translateY: -60,
        opacity: 0,
    },
    menuOpen: {
        scale: 1, // Reset scale
        translateY: 0,
        scaleX: 1,
        opacity: 1,
    },
};

const bgVariants = {
    menuClosed: {
        opacity: 0,
    },
    menuOpen: {
        opacity: 1,
    },
};

type HeaderProps = {
    selectedId: string;
    setSelectedId: (selectedId: SearchHeaderSelection) => void;
    setMenuOpen: (menuOpen: boolean) => void;
    previousSelectedIds: SearchHeaderSelection[];
    setPreviousSelectedIds: (
        previousSelectedIds: SearchHeaderSelection[]
    ) => void;
};

const FormHeader = ({
    selectedId,
    setSelectedId,
    setMenuOpen,
    previousSelectedIds,
    setPreviousSelectedIds,
}: HeaderProps) => {
    return (
        <div className="flex flex-row justify-between">
            <button
                id="back-button"
                onClick={() => {
                    if (previousSelectedIds.length === 0) {
                        return;
                    }
                    const previousId =
                        previousSelectedIds[previousSelectedIds.length - 1];
                    setPreviousSelectedIds([
                        ...previousSelectedIds.slice(
                            0,
                            previousSelectedIds.length - 1
                        ),
                    ]);
                    setSelectedId(previousId);
                }}
                className="p-4 pl-0"
            >
                {previousSelectedIds.length > 0 ? (
                    <ButtonArrow className="stroke-black opacity-50 -scale-x-100" />
                ) : null}
            </button>
            <button
                onClick={() => {
                    setMenuOpen(false);
                }}
                className="p-4 pr-0"
            >
                <CloseX />
            </button>
        </div>
    );
};

interface TakeoverFormProps {
    menuOpen: boolean;
    duration: number;
    showMenu?: boolean;
    setMenuOpen: (menuOpen: boolean) => void;
}

const TakeoverForm: React.FC<TakeoverFormProps> = ({
    menuOpen,
    duration,
    setMenuOpen,
    showMenu = false,
}) => {
    const [activeVariant, isHidden, setIsHidden] =
        useOpenClosedAnimation(menuOpen);

    const { globalPlan, initialPlannerOpen } = useAppContext();

    useEffect(() => {
        if (initialPlannerOpen) {
            setMenuOpen(true);
        }
    }, [initialPlannerOpen]);

    return (
        <>
            <motion.div
                className="flex justify-center align-center pointer-events-none -z-10"
                style={{ originX: 0.5, originY: 0 }}
                variants={bgVariants}
                animate={activeVariant}
                initial="menuClosed"
                transition={{
                    duration,
                }}
                onAnimationStart={() => {
                    if (activeVariant === "menuOpen") {
                        setIsHidden(false);
                    }
                }}
                onAnimationComplete={() => {
                    if (activeVariant === "menuClosed") {
                        setIsHidden(true);
                    }
                }}
            >
                <div className="bg-black w-full h-full fixed -z-10 opacity-20 left-0 top-0 pointer-events-none" />
            </motion.div>
            <div className="fixed top-0 z-10 w-full h-full pointer-events-none">
                {/* <div className="h-[--header-height] w-full pointer-events-none bg-gatha-secondary-white z-50" /> */}
                <motion.div
                    className="flex h-full w-full justify-center items-center"
                    style={{ originX: 0.5, originY: 0 }}
                    variants={variants}
                    animate={activeVariant}
                    initial="menuClosed"
                    transition={{
                        duration,
                    }}
                    onAnimationStart={() => {
                        if (activeVariant === "menuOpen") {
                            setIsHidden(false);
                        }
                    }}
                    onAnimationComplete={() => {
                        if (activeVariant === "menuClosed") {
                            setIsHidden(true);
                        }
                    }}
                >
                    {isHidden || !showMenu ? null : (
                        <div className="flex flex-col gap-3 p-20 w-full max-w-3xl">
                            <div className="flex flex-col relative pointer-events-auto bg-white overflow-hidden w-full p-10 pt-5 pb-8 rounded-3xl drop-shadow-glow-small gap-10 h-[600px] justify-between">
                                <OpenPanelView setMenuOpen={setMenuOpen} />
                            </div>
                        </div>
                    )}
                </motion.div>
            </div>
        </>
    );
};

const panels: Map<
    SearchHeaderSelection,
    { class: React.ComponentType; props: {} }
> = new Map([
    [Intro, { class: UniquePlanIntro, props: {} }],
    [Vision, { class: UniquePlanVision, props: {} }],
    [DateTime, { class: UniquePlanDateTime, props: {} }],
    [Location, { class: UniquePlanLocation, props: {} }],
]);

type OpenPanelProps = {
    setMenuOpen: (menuOpen: boolean) => void;
};

const OpenPanelView = ({ setMenuOpen }: OpenPanelProps) => {
    const path = window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/") + 1
    );
    const urlPlanId = path.length > 8 ? path : null;

    const [selectedId, setSelectedId] = useState<SearchHeaderSelection>(
        urlPlanId ? Vision : Intro
    );

    const creationDetails = panels.get(selectedId);
    const CreationClass: React.ComponentType<CommonPanelProps> =
        creationDetails?.class;

    const { globalPlan, setGlobalPlan } = useAppContext();

    const [permission, setPermission] = useState("unknown");

    if (permission === "unauthorized") {
        // router.push("/auth/user");
        return notFound();
    }

    const [previousSelectedIds, setPreviousSelectedIds] = useState<
        SearchHeaderSelection[]
    >([]);

    return CreationClass ? (
        <>
            <FormHeader
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                setMenuOpen={setMenuOpen}
                previousSelectedIds={previousSelectedIds}
                setPreviousSelectedIds={setPreviousSelectedIds}
            />
            <CreationClass
                existingPlan={globalPlan}
                navigateToPanel={(panelId) => {
                    setPreviousSelectedIds([
                        ...previousSelectedIds,
                        selectedId,
                    ]);
                    setSelectedId(panelId);
                }}
                {...creationDetails.props}
            />
        </>
    ) : null;
};

export default TakeoverForm;

"use client";
import LondonMap from "../icons/maps/london";
import ManchesterMap from "../icons/maps/manchester";

interface LocationMapProps {
    className?: string;
    location: string;
    enabled?: boolean;
    onClick?: (event) => void;
}

const getLocationMap = (location: string) => {
    if (location === "London, UK") {
        return (
            <div className="p-4">
                <LondonMap />
            </div>
        );
    }

    if (location === "Manchester, UK") {
        return (
            <div className="p-4">
                <ManchesterMap />
            </div>
        );
    }
    return <div>Picture of {location}</div>;
};

const doClick = (location: string) => {
    console.log(location);
};

const LocationMap = ({
    location,
    onClick,
    enabled = true,
}: LocationMapProps) => {
    return (
        <div
            className={`${
                enabled === true
                    ? "cursor-pointer"
                    : "select-none cursor-auto opacity-30"
            } flex flex-col gap-1 `}
            onClick={() => doClick(location)}
        >
            <div className="pointer-events-none flex justify-center align-middle rounded-xl border border-gray-300 h-32 w-32">
                {getLocationMap(location)}
            </div>
            <div className="text-xs">{location}</div>
        </div>
    );
};

export default LocationMap;

"use client";

import { cn, verboseLog } from "@/lib/utils";
import { useEffect, useState } from "react";

import { CommonPanelProps } from "@/type/app/layout/takeover-form/common-panel-props";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { Location } from "@/type/plan/unique-flow";
import UniquePlanStatus from "./unique-plan-status";
import { useDebounceStateChange } from "@/hooks/debounce-hooks";
import { usePlanFormSave } from "@/hooks/unique-plan";

const menuItems = [
    ["Create Event", "/plan"],
    ["FAQ", "/faq"],
];

const variants = {
    menuOpen: {
        scaleX: 2, // Scale to twice the original size
        scaleY: 1.3,
        translateY: 30,
        opacity: 0,
    },
    menuClosed: {
        scale: 1, // Reset scale
        translateY: 0,
        opacity: 1,
    },
};

const getValidInputTimeString = (date: Date) => {
    const dateTimeLocalValue = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
    )
        .toISOString()
        .slice(0, -1);

    return dateTimeLocalValue;
};

const getDatesFromExistingPlan = (existingPlan) => {
    if (existingPlan) {
        return existingPlan.eventDates.map((date) => new Date(date));
    } else {
        return [new Date()];
    }
};

const AUTO_SAVE_DEBOUNCE_MS = 500;

const UniquePlanDateTime: React.FC<CommonPanelProps> = ({
    storyBookProps,
    existingPlan,
    navigateToPanel,
}) => {
    // Valid dates should be provided. If not add a single date as today
    const [eventDates, setEventDates] = useState(
        getDatesFromExistingPlan(existingPlan)
    );
    const [numberOfGuests, setNumberOfGuests] = useState(
        existingPlan?.numberOfGuests
    );

    const {
        canSave,
        saveProvidedPlan,
        updatedPlan,
        failureReason,
        globalPlan,
    } = storyBookProps
        ? {
              canSave: true,
              saveProvidedPlan: () => {},
              updatedPlan: null,
              failureReason: null,
              globalPlan: {},
              ...storyBookProps,
          }
        : usePlanFormSave();

    useEffect(() => {
        verboseLog("Plan updated", updatedPlan);
    }, [updatedPlan]);

    useEffect(() => {
        verboseLog("Plan update failed", failureReason);
    }, [failureReason]);

    useDebounceStateChange(
        [numberOfGuests, eventDates],
        () => {},
        () => {
            saveProvidedPlan({
                ...globalPlan,
                numberOfGuests,
                selectedDate: null,
                eventDates: eventDates.map((date) => date.toISOString()),
            });
        },
        AUTO_SAVE_DEBOUNCE_MS
    );

    return (
        <div className="w-full h-full flex flex-col relative justify-between">
            <UniquePlanStatus
                status={{ completed: [true, false, false], stage: 2 }}
            />
            <div className="flex flex-col justify-between gap-6 w-full">
                <div className="w-full flex flex-row gap-6">
                    <div className="flex flex-col gap-1 w-52">
                        <div className="font-semibold text-sm">
                            Number of guests
                        </div>
                        <div className="text-xs">
                            Not 100% sure? Share the plan to see who can make
                            it.
                        </div>
                    </div>

                    <InputWithLabel
                        className="w-md"
                        onChange={(event) => {
                            setNumberOfGuests(event.target.value);
                        }}
                        formId="guests"
                        type="number"
                        placeholder="8"
                        value={numberOfGuests || ""}
                    />
                </div>

                <div className="w-full flex flex-row gap-6 justify-between">
                    <div className="flex flex-col gap-1 w-52">
                        <div className="font-semibold text-sm">When</div>
                        <div className="text-xs">
                            Want to see what date works best for your guests?
                            Select multiple and share the plan.
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        {eventDates.map((date, index) => (
                            <div
                                className="flex flex-row w-full h-10"
                                key={date.getTime()}
                            >
                                <InputWithLabel
                                    // alwaysAlignColumn={alwaysAlignColumn}
                                    className="flex-1"
                                    formId="dates"
                                    type="datetime-local"
                                    value={getValidInputTimeString(date)}
                                    showPlusIcon={true}
                                    onPlusClick={() => {
                                        setEventDates([
                                            ...eventDates,
                                            new Date(),
                                        ]);
                                    }}
                                    onChange={(event) => {
                                        setEventDates(
                                            eventDates.map(
                                                (date, previousIndex) => {
                                                    if (
                                                        previousIndex === index
                                                    ) {
                                                        return new Date(
                                                            event.target.value
                                                        );
                                                    } else {
                                                        return date;
                                                    }
                                                }
                                            )
                                        );
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    <button
                        disabled={!canSave}
                        onClick={() => {
                            navigateToPanel(Location);
                        }}
                        className={cn(
                            "bg-gatha-dark-purple rounded-full flex flex-row p-3 pl-20 pr-20 items-center gap-2 justify-center w-fit",
                            true ? "opacity-100" : "opacity-20"
                        )}
                    >
                        <div
                            className={cn(
                                "text-white font-light",
                                canSave ? "opacity-100" : "opacity-10"
                            )}
                        >
                            {canSave ? "Next" : "Next"}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export { getDatesFromExistingPlan };

export default UniquePlanDateTime;

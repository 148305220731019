"use client";

import ButtonArrow from "@/components/icons/button-arrow";
import { cn } from "@/lib/utils";

const menuItems = [
    ["Create Event", "/plan"],
    ["FAQ", "/faq"],
];

const variants = {
    menuOpen: {
        scaleX: 2, // Scale to twice the original size
        scaleY: 1.3,
        translateY: 30,
        opacity: 0,
    },
    menuClosed: {
        scale: 1, // Reset scale
        translateY: 0,
        opacity: 1,
    },
};

const Stars = () => {
    return (
        <svg
            className="stroke-white fill-transparent"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M9 1.5L7.407 6.344a1.667 1.667 0 01-1.063 1.063L1.5 9l4.844 1.593a1.667 1.667 0 011.063 1.063L9 16.5l1.593-4.844a1.666 1.666 0 011.063-1.063L16.5 9l-4.844-1.593a1.666 1.666 0 01-1.063-1.063L9 1.5zM3.167 1.5v3.333M14.833 13.167V16.5M1.5 3.167h3.333M13.167 14.833H16.5"
            ></path>
        </svg>
    );
};

interface Props {
    onClickHandler: (event: any) => void;
    className?: string;
}

const UniquePlanButton: React.FC<Props> = ({ className, onClickHandler }) => {
    return (
        <button
            onClick={(event) => onClickHandler(event)}
            className={cn(
                "bg-gatha-dark-purple rounded-full flex flex-row p-3 pl-6 pr-5 items-center gap-2",
                className
            )}
        >
            <Stars />
            <div className="text-white font-light">Set Up My Unique Plan</div>
            <ButtonArrow />
        </button>
    );
};

export default UniquePlanButton;
